import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "lemajic_best_2019" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Client of Catenaccio Sports Agency <Link to="/en/players/lemajic">Darko Lemajic</Link> was named the best player of 2019 in Latvian Championship. Last season, Darko scored 15 goals and became the top scorer of the championship. Great result, Darko, congratulations!</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/j2wExJFpuZQ"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}